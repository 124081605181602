const colorMap = {
    blue: { bgClass: 'bg-blue-500', hoverClass: 'hover:bg-blue-400', textClass: 'text-blue-500' },
    red: { bgClass: 'bg-red-500', hoverClass: 'hover:bg-red-400', textClass: 'text-red-500' },
    yellow: { bgClass: 'bg-yellow-500', hoverClass: 'hover:bg-yellow-400', textClass: 'text-yellow-500' },
    green: { bgClass: 'bg-green-500', hoverClass: 'hover:bg-green-400', textClass: 'text-green-500' },
    purple: { bgClass: 'bg-purple-500', hoverClass: 'hover:bg-purple-400', textClass: 'text-purple-500' },
    orange: { bgClass: 'bg-orange-500', hoverClass: 'hover:bg-orange-400', textClass: 'text-orange-500' },
    emerald: { bgClass: 'bg-emerald-400', hoverClass: 'hover:bg-emerald-300', textClass: 'text-emerald-400' },
    violet: { bgClass: 'bg-violet-400', hoverClass: 'hover:bg-violet-300', textClass: 'text-violet-400' },
    pink: { bgClass: 'bg-pink-500', hoverClass: 'hover:bg-pink-400', textClass: 'text-pink-500' },
    sky: { bgClass: 'bg-sky-300', hoverClass: 'hover:bg-sky-200', textClass: 'text-sky-300' },
    cyan: { bgClass: 'bg-cyan-500', hoverClass: 'hover:bg-cyan-400', textClass: 'text-cyan-500' },
    teal: { bgClass: 'bg-teal-500', hoverClass: 'hover:bg-teal-400', textClass: 'text-teal-500' },
    lime: { bgClass: 'bg-lime-500', hoverClass: 'hover:bg-lime-400', textClass: 'text-lime-500' },
    amber: { bgClass: 'bg-amber-500', hoverClass: 'hover:bg-amber-400', textClass: 'text-amber-500' },
    darkGreen: { bgClass: 'bg-green-700', hoverClass: 'hover:bg-green-600', textClass: 'text-green-700' },
    darkEmerald: { bgClass: 'bg-emerald-600', hoverClass: 'hover:bg-emerald-500', textClass: 'text-emerald-600' },
    zinc: { bgClass: 'bg-zinc-700', hoverClass: 'hover:bg-zinc-600', textClass: 'text-zinc-700' },
    stone: { bgClass: 'bg-stone-400', hoverClass: 'hover:bg-stone-300', textClass: 'text-stone-400' },
    stone2: { bgClass: 'bg-stone-600', hoverClass: 'hover:bg-stone-500', textClass: 'text-stone-600' },
    slate: { bgClass: 'bg-slate-400', hoverClass: 'hover:bg-slate-300', textClass: 'text-slate-400' },
    darkRed: { bgClass: 'bg-red-700', hoverClass: 'hover:bg-red-600', textClass: 'text-red-700' },
    lightRed: { bgClass: 'bg-red-300', hoverClass: 'hover:bg-red-200', textClass: 'text-red-300' },
    violet: { bgClass: 'bg-blue-300', hoverClass: 'hover:bg-blue-200', textClass: 'text-violet-300' },
    default: { bgClass: 'bg-gray-500', hoverClass: 'hover:bg-gray-400', textClass: 'text-gray-500' },
};

export const getColor = (color) => colorMap[color] || colorMap.default;

export const getBorder = (color) => {
    return `border-${color}-500`;
};